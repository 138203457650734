import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

//注册路由
const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/home',
    },
    {
        path: '',
        name: 'Index',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home')
    },
    {
        path: '/service',
        name: 'Service',
        children: [
            {
                path: '/AfterSales',
                name: 'AfterSales',
                component: () => import('@/views/Service/AfterSales')
            },
            {
                path: '/DataSupport',
                name: 'DataSupport',
                component: () => import('@/views/Service/DataSupport')
            },
            {
                path: '/OperationalSupport',
                name: 'OperationalSupport',
                component: () => import('@/views/Service/OperationalSupport')
            },
            {
                path: '/ProjectImplement',
                name: 'ProjectImplement',
                component: () => import('@/views/Service/ProjectImplement')
            },
        ]
    },
    {
        path: '/product',
        name: 'Product',
        children: [
            {
                path: '/alumni',
                name: 'Alumni',
                component: () => import('@/views/Product/Alumni')
            },
            {
                path: '/CloudActivity',
                name: 'CloudActivity',
                component: () => import('@/views/Product/CloudActivity')
            },
            {
                path: '/DataService',
                name: 'DataService',
                component: () => import('@/views/Product/DataService')
            },
            {
                path: '/donation',
                name: 'Donation',
                component: () => import('@/views/Product/Donation')
            },
            {
                path: '/foundation',
                name: 'Foundation',
                component: () => import('@/views/Product/Foundation')
            },
            {
                path: '/ResourceManage',
                name: 'ResourceManage',
                component: () => import('@/views/Product/ResourceManage')
            },
            {
                path: '/WebsiteGroup',
                name: 'WebsiteGroup',
                component: () => import('@/views/Product/WebsiteGroup')
            },
        ]
    },
    {
        path: '/solution',
        name: 'Solution',
        children: [
            {
                path: '/FoundationSolution',
                name: 'FoundationSolution',
                component: () => import('@/views/Solution/FoundationSolution')
            },
            {
                path: '/AlumniSolution',
                name: 'AlumniSolution',
                component: () => import('@/views/Solution/AlumniSolution')
            },
            {
                path: '/OperationalSolution',
                name: 'OperationalSolution',
                component: () => import('@/views/Solution/OperationalSolution')
            },
            {
                path: '/DataSolution',
                name: 'DataSolution',
                component: () => import('@/views/Solution/DataSolution')
            },
        ]
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About')
    },
    {
        path: '/case',
        name: 'Case',
        component: () => import('@/views/Case')
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/News')
    },
    {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: () => import('@/views/NewsDetail')
    },
    {
        path:"/joinus",
        name: "JoinUs",
        component:()=>import('@/views/JoinUs/JoinUs'),
        meta: { hideFooter: true }
    },
    {
        path: "/404", // 不识别的path自动匹配404
        component: () => import('@/views/404')
    },
    {
        path: "/:catchAll(.*)", // 不识别的path自动匹配404
        redirect: '/404',
    },
];

const pathName = window.location.pathname
const base = pathName.substring(0, pathName.substr(1).indexOf('/') + 1);
const router = createRouter({
// createWebHashHistory()是使用hash模式路由
// createWebHistory()是使用history模式路由
    history: createWebHistory(base),
    routes
});


export default router;
