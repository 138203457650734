<template>
  <div class="container">
    <NavBar />
    <div class="main-content">
      <router-view />
    </div>
    <Footer v-if="!hideFooter"/>
  </div>
</template>

<script>

import NavBar from '@/components/Nav/NavBar'
import Footer from "@/components/Footer/Footer";

export default {
  name: 'Index',
  components: {Footer, NavBar},
  data(){
    return{
      hideFooter:false
    }
  },
  mounted() {
    // prerender-spa-plugin,触发 renderAfterDocumentEvent
    document.dispatchEvent(new Event('render-event'))
  },
  watch: {
    '$route.meta.hideFooter'(newValue) {
      this.hideFooter = newValue;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.hideFooter = to.meta.hideFooter || false;
    });
  }
}


const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-content {
  /* padding: 0 0 905px 0; */
}

.container {
  /*使竖向滚动条不占位，防止挤压页面*/
  /*overflow-x: overlay; */
}
</style>
