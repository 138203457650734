<template>
  <div class="foot-content" v-cloak>
    <div class="foot1">
      <div class="foot1-box">
        <div class="text">
          <span class="text1">蓝矩，不仅仅是先进的系统提供商，还是领先的，<br>集“数据、运营、咨询”于一体的行业数字化服务商。</span>
        </div>
        <div class="info">
          <span class="text2">扫码添加蓝小矩好友</span>
          <span class="text3">或者您也可以填写表单</span>
          <div class="btn">
            <a class="btn-a" href="https://data.laungee.com/form/318" target="_blank">联系我们</a>
          </div>
        </div>
      </div>
    </div>
    <div class="foot2">
      <div class="top">
        <div class="list">
          <div class="left">
            <div class="box" v-for="(item,index) in footItem" :key="index">
              <h6 class="title">{{ item.title }}</h6>
              <ul>
                <li class="item" v-for="(item1,index1) in item.children" :key="index1">
                  <router-link :to="item1.url" target="_blank">{{ item1.name }}</router-link>
                </li>
              </ul>
            </div>
            <div class="box">
              <h6 class="title">关于蓝矩</h6>
              <ul>
                <li class="item" v-for="(item,index) in aboutList" :key="index">
                  <span @click="goUrl('/about',item.id)">{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <img class="img" src="../../assets/footer/logo2.png">
            <div class="addr">
              <div>地址：上海杨浦国霞路259号708-718室</div>
              <div>电话：021-55900188</div>
              <div>邮箱：VIP@LAUNGEE.COM</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="btm-txt">
          版权所有 © 2023 上海蓝矩信息科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备07507831号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import {ref} from "vue";

const footItem = ref([
  {
    title: '蓝矩产品',
    children: [
      {
        name: '校友会产品',
        url: '/alumni',
      },
      {
        name: '基金会产品',
        url: '/foundation',
      },
      {
        name: '云捐赠系统',
        url: '/donation',
      },
      {
        name: '数据服务平台',
        url: '/DataService',
      },
      {
        name: '重点资源管理',
        url: '/ResourceManage',
      },
      {
        name: '网站群系统',
        url: '/WebsiteGroup',
      },
    ]
  },
  {
    title: '蓝矩服务',
    children: [
      {
        name: '售后服务',
        url: '/AfterSales',
      },
      {
        name: '数据支持服务',
        url: '/DataSupport',
      },
      {
        name: '运营支持服务',
        url: '/OperationalSupport',
      },
      {
        name: '项目实施服务',
        url: '/ProjectImplement',
      }
    ]
  },
  {
    title: '解决方案',
    children: [
      {
        name: '基金会解决方案',
        url: '/FoundationSolution',
      },
      {
        name: '校友会解决方案',
        url: '/AlumniSolution',
      },
      {
        name: '数据解决方案',
        url: '/DataSolution',
      },
      {
        name: '运营解决方案',
        url: '/OperationalSolution',
      }
    ]
  },
  {
    title: '典型案例',
    children: [
      {
        name: '案例分享',
        url: '/case',
      },
      {
        name: '合作高校',
        url: '/case',
      }
    ]
  },
  {
    title: '新闻资讯',
    children: [
      {
        name: '新闻资讯',
        url: '/news',
      }
    ]
  }
])

const aboutList = ref([{
  name: '关于我们',
  id: 'about'
}, {
  name: '荣誉资质',
  id: 'honor'
}, {
  name: '行业研究',
  id: 'study'
}, {
  name: '新闻资讯',
  id: 'news'
}])

const goUrl = (url, id) => {
  sessionStorage.setItem('id', id)
  const href = router.resolve({     //使用resolve
    path: url,
  })
  window.open(href.href, '_blank')
}
</script>

<style scoped lang="scss">

.foot-content {
  //position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.foot1 {
  background: #2165FB;
}

.foot1-box {
  margin: 0 auto;
  width: 1920px;
  height: 494px;
  position: relative;
  background: url(../../assets/footer/foot-bg.png) no-repeat center;
  background-size: cover;
  color: #fff;

  .text {
    position: absolute;
    top: 65px;
    left: 568px;

    .text1 {
      font-size: 36px;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
    }
  }

  .text2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
  }

  .text3 {
    font-size: 18px;
    line-height: 30px;
  }

  .info {
    position: absolute;
    top: 255px;
    left: 786px;
    display: flex;
    flex-direction: column;

    .btn {
      width: 140px;
      height: 44px;
      line-height: 44px;
      background: #FFA100;
      border-radius: 22px 22px 22px 22px;
      text-align: center;
      font-size: 18px;
      margin-top: 20px;

      .btn-a {
        display: block;
        width: 140px;
        height: 44px;
      }
    }
  }
}

.foot2 {
  margin-top: -2px;
  width: 100%;
  height: 499px;
  position: relative;
  background: url(../../assets/footer/foot-bg2.png) no-repeat center;
  background-size: cover;
  color: #fff;

  .top {
    display: flex;
    align-items: center;
    height: 428px;
    width: 1920px;
    margin: 0 auto;

    .list {
      display: flex;
      margin-left: 260px;

      .left {
        display: flex;
        border-right: 1px solid #B7CEFF;

        .box {
          width: 170px;

          .title {
            margin-bottom: 40px;
            font-size: 24px;
            line-height: 24px;
            font-weight: 500;
            font-family: SourceHanSansCN-Medium;
          }

          .item {
            color: #fff;
            margin-bottom: 12px;
            font-size: 19px;
            font-weight: 400;
            cursor: pointer;

            a {
              color: #ffffff;
              font-family: SourceHanSansCN-Normal;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 50px;

        .img {
          display: block;
          width: 92px;
          height: 83px;
        }

        .addr {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }

  .bottom {
    height: 70px;
    border-top: 1px solid #C8CBDA;
    line-height: 70px;

    .btm-txt {
      width: 1400px;
      margin: 0 auto;
      font-family: SourceHanSansCN-Normal;
    }
  }
}
</style>
