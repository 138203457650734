import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './assets/css/main.css'
import './assets/css/index.scss'
import './assets/css/mixins.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './util/rem'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import MetaInfo from 'vue-meta-info'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(router).use(ElementPlus).use(MetaInfo).mount('#app')
